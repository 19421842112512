<template lang="pug">
span
  span.norwap(v-if='invoice.file')
    a(@click='download')
      icon(v-if='onlyIcons' icon='file-download')
      span(v-else) {{ invoice.file }}
    a.ml-1(@click='preview')
      icon(icon='eye')
  span(v-if='!invoice.file && !onlyIcons') Sin archivo
</template>

<script>
import invoicesService from '@/services/invoices.service'

export default {
  props: {
    invoice: { type: Object, required: true },
    onlyIcons: { type: Boolean, default: false }
  },
  methods: {
    async download () {
      await invoicesService.download(this.invoice)
    },
    async preview () {
      await invoicesService.preview(this.invoice)
    }
  }
}
</script>
