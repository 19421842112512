import api, { buildService } from './index'
import filesService from './files.service'

export default {
  ...buildService('invoices'),
  pendingForPrint: () => api.get('invoices/bot/pending'),
  savePendingForPrintFile: invoice => api.post('invoices/bot/pending', invoice),
  download: invoice => filesService.download('invoices', invoice.file, { invoiceId: invoice.id }),
  preview: invoice => filesService.previewPdf('invoices', invoice.file, { invoiceId: invoice.id })
}
