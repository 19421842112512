<template lang="pug">
form.container(@submit.prevent='submit')
  b-loading(:is-full-page='false' v-model='isLoading')
  page-title(:title='invoice.id ? `Factura #${invoice.id} de ${invoice.assignedName}` : `Nueva factura`')
    button.button.is-primary(type='submit') Guardar
  .card
    .card-content
      div.invoice-form.form-horizontal.mb-3
        field(label='Contrato' field='contractId')
          table-picker(id='contractId' :data='contracts' :isLoading='isLoadingContracts' v-model='invoice.contract' @input='contractChanged')
        field(label='A quién generar' required)
          b-radio(v-model='who' name='who' native-value='client') Cliente
          b-radio(v-model='who' name='who' native-value='distributor') Distribuidor
        field(label='Distribuidor' field='distributor' v-if='who === "distributor"' required)
          app-select(id='distributor' :items='distributors' v-model.number='invoice.distributorId' :is-loading='loadingDistributors' required)
        field(label='Cliente' field='client' v-if='who === "client"' required)
          app-select(id='client' :items='clients' v-model.number='invoice.clientId'  required)
        field(label='Frec. de cobro' field='chargeFrequency' required)
          app-select(id='chargeFrequency' :enum='chargeFrequencyTypeEnum' :enum-names='chargeFrequencyTypeDescriptions' v-model.number='invoice.chargeFrequency' required)
        field(:label='labelPrice' field='price' required)
          input#price.input(type='number' step='0.01' placeholder='Precio' min='0' required v-model.number='invoice.price')
        field(label='Fecha' field='date' required)
          app-date(id='date' v-model='invoice.date' required)
        field(label='Inicio del ciclo' field='startDate' required)
          app-date(id='startDate' v-model='invoice.startDate' required)
        field(label='Fin del ciclo' field='endDate')
          app-date(id='endDate' v-model='invoice.endDate')
        field(label='Expira' field='expireDate' required)
          app-date(id='expireDate' v-model='invoice.expireDate' required)
        //- td Total
        //- td {{ invoice.total | currency  }}
      collapse-card(title='Detalles de equipos')
        table.table
          thead
            tr
              th Nombre registrado
              th IMEI
              th Cantidad
              th.numeric Precio/{{chargeFrecuencyLabel}}
              th.numeric Subtotal
              th
          tbody
            tr(v-for='(detail, index) in invoice.invoiceDetailsDto')
              td {{ detail.deviceName }}
              td {{ detail.deviceId }}
              td {{ isDaily ? detail.days : detail.months  }} {{ chargeFrequencyTypeDescription }}
              td.numeric {{ invoice.price | currency }}
              td.numeric {{ invoice.price * (isDaily ? detail.days : detail.months) | currency }}
              td
                a(@click='removeDetail(detail, index)')
                  icon(icon='trash' title='Eliminar')
        a(@click='addNewDevice') Agregar un nuevo equipo
      collapse-card(title='Otros conceptos')
        p.mb-5(v-if='otherDetails.length === 0') No hay otros conceptos agregados
        table.table(v-else)
          thead
            tr
              th Concepto
              th Cantidad
              th.numeric Precio
              th.numeric Subtotal
              th
          tbody
            tr(v-for='(detail, index) in otherDetails')
              td {{ detail.concept }}
              td {{ detail.quantity }}
              td.numeric {{ detail.unitCost | currency }}
              td.numeric {{ detail.unitCost * detail.quantity | currency }}
              td
                a(@click='removeOtherDetail(detail, index)')
                  icon(icon='trash' title='Eliminar')
        a(@click='addNewConcept') Agregar un nuevo concepto
  b-modal(v-model='formDetailModalActive' has-modal-card trap-focus aria-role='dialog' aria-modal :can-cancel='["x"]')
    modal-card(v-if='detail' title='Crear nuevo equipo' width='400px')
      app-form(@submit.prevent='submitDetail' :is-saving='isSaving')
        field(label='Equipos' field='deviceId')
          devices-picker(id='deviceId' v-model='detail.devices' multiple)
        field(label='Dias cobrados' field='days')
          input#quantity.input(placeholder='Ejm: 31' required v-model.number='detail.days' type='number')
        field(label='Meses cobrados' field='months')
          input#unitCost.input(placeholder='Ejm: 1' required v-model.number='detail.months' type='number')
        field(label='Valor/Precio' field='value')
          input#unitCost.input(placeholder='Precio por mes o día según el contrato' required v-model.number='detail.value' type='number')
  b-modal(v-model='formOtherDetailModalActive' has-modal-card trap-focus aria-role='dialog' aria-modal :can-cancel='["x"]')
    modal-card(v-if='otherDetail' title='Crear nuevo concepto' width='400px')
      app-form(@submit.prevent='submitOtherDetail' :is-saving='isSaving')
        field(label='Concepto' field='concept')
          input#concept.input(placeholder='Ejm. Servicio técnico de instalación' required v-model='otherDetail.concept')
        field(label='Cantidad' field='quantity')
          input#quantity.input(placeholder='Cantidad' required v-model.number='otherDetail.quantity' type='number')
        field(label='Valor por unidad' field='unitCost')
          input#unitCost.input(placeholder='Valor de cada unidad' required v-model.number='otherDetail.unitCost' type='number')
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { format } from 'date-fns'
import InvoiceFileLink from './blocks/InvoiceFileLink'
import InvoiceStatusIndicator from './blocks/InvoiceStatusIndicator'
import invoicesService from '@/services/invoices.service'
import DevicesPicker from '@/components/devices/DevicesPicker'
import TablePicker from '@/components/shared/TablePicker.vue'
import { chargeFrequencyTypeEnum, chargeFrequencyTypeDescriptions } from '@/constants/enums'
import toastService from '@/services/toast.service'
import contractsService from '@/services/contracts.service'
import distributorsService from '@/services/distributors.service'

const defaultInvoice = () => ({
  clientId: null,
  date: format(new Date(), 'yyyy-MM-dd'),
  invoiceOtherDetails: [],
  invoiceDetailsDto: []
})

export default {
  components: { InvoiceFileLink, InvoiceStatusIndicator, DevicesPicker, TablePicker },
  data () {
    return {
      invoice: defaultInvoice(),
      who: 'client',
      contracts: [],
      distributors: [],
      chargeFrequencyTypeEnum,
      chargeFrequencyTypeDescriptions,
      isLoading: false,
      isLoadingContracts: false,
      formDetailModalActive: false,
      formOtherDetailModalActive: false,
      detail: null,
      otherDetail: null,
      isSaving: false
    }
  },
  created () {
    if (this.$route.params.id) {
      this.getInvoice()
    } else {
      this.getContracts()
    }
    this.getClients()
  },
  computed: {
    ...mapState({
      clientsLoading: state => state.clients.loading,
      clients: state => state.clients.list
    }),
    contract () {
      return (this.invoice && this.invoice.contract) || {}
    },
    chargeFrecuencyLabel () {
      switch (this.invoice.chargeFrequency) {
        case chargeFrequencyTypeEnum.Daily: return 'día'
        case chargeFrequencyTypeEnum.Montly: return 'mes'
        default: return '???'
      }
    },
    labelPrice () {
      switch (this.invoice.chargeFrequency) {
        case chargeFrequencyTypeEnum.Daily: return 'Precio por día'
        case chargeFrequencyTypeEnum.Montly: return 'Precio por mes'
        default: return 'Precio'
      }
    },
    chargeFrequencyTypeDescription () {
      return chargeFrequencyTypeDescriptions[this.invoice.chargeFrequency]
    },
    isDaily () {
      return this.invoice.chargeFrequency === chargeFrequencyTypeEnum.Daily
    },
    otherDetails () {
      return (this.invoice && this.invoice.invoiceOtherDetails) || []
    }
  },
  watch: {
    who (newValue) {
      if (newValue === 'distributor') {
        if (!this.distributors.length) this.getDistributors()
        this.invoice.clientId = null
      } else {
        this.invoice.distributorId = null
      }
    }
  },
  methods: {
    ...mapActions({
      getClients: 'clients/getClients'
    }),
    async getDistributors () {
      this.loadingDistributors = true
      const result = await distributorsService.getAll()
      if (result) {
        this.distributors = result
      }
      this.loadingDistributors = false
    },
    async getInvoice () {
      this.isLoading = true
      const id = Number(this.$route.params.id)
      const result = await invoicesService.getOne(id)
      if (result) {
        this.invoice = result
      }
      this.isLoading = false
    },
    async getContracts () {
      this.isLoadingContracts = true
      const result = await contractsService.getAll()
      if (result) {
        this.contracts = result
      }
      this.isLoadingContracts = false
    },
    contractChanged () {
      if (this.invoice.contract) {
        this.invoice.contractId = this.invoice.contract.id
        this.invoice.chargeFrequency = this.invoice.contract.chargeFrequency
        this.invoice.price = this.invoice.contract.price
        if (this.clients.some(c => c.id === this.invoice.contract.clientId)) {
          this.invoice.clientId = this.invoice.contract.clientId
        }
        this.invoice.distributorId = this.invoice.contract.distributorId
        if (this.invoice.contract.clientId) {
          this.who = 'client'
        }
        if (this.invoice.contract.distributorId) {
          this.who = 'distributor'
        }
      } else {
        this.invoice.contractId = null
      }
    },
    addNewDevice () {
      if (this.invoice.invoiceDetailsDto.length) {
        const { days, months, value } = this.invoice.invoiceDetailsDto[0]
        this.detail = { days, months, value }
      } else {
        this.detail = {}
      }
      this.formDetailModalActive = true
    },
    addNewConcept () {
      this.otherDetail = {}
      this.formOtherDetailModalActive = true
    },
    async submitDetail () {
      const invoiceId = this.invoice.id
      const devicesIds = this.detail.devices.map(device => device.id)
      const existentDevices = this.invoice.invoiceDetailsDto.filter(detail => devicesIds.includes(detail.deviceId))
      if (existentDevices.length) {
        const existentDevicesIds = existentDevices.map(detail => detail.deviceId)
        if (!await toastService.confirmWarning(`Los siguiendes equipos ya está en la lista\r\n${existentDevicesIds.join(', ')}. ¿seguro que desea agregarlos?`)) return
      }

      for (let i = 0; i < this.detail.devices.length; i++) {
        const deviceId = this.detail.devices[i].id
        const deviceName = this.detail.devices[i].name
        const detail = { ...this.detail, deviceId, invoiceId, deviceName }
        this.invoice.invoiceDetailsDto.push(detail)
      }
      this.formDetailModalActive = false
      this.detail = null
    },
    async submitOtherDetail () {
      const invoiceId = this.invoice.id
      const detail = { invoiceId, ...this.otherDetail }
      this.invoice.invoiceOtherDetails.push(detail)
      this.$set(this, 'invoice', this.invoice)
      this.formOtherDetailModalActive = false
      this.otherDetail = null
    },
    async removeDetail (detail, index) {
      if (!await toastService.confirmWarning('¿Desea eliminar este elemento?')) return
      this.$set(this.invoice, 'invoiceDetailsDto', this.invoice.invoiceDetailsDto.filter((det, _index) => _index !== index))
    },
    async removeOtherDetail (detail, index) {
      if (!await toastService.confirmWarning('¿Desea eliminar este elemento?')) return
      this.invoice.invoiceOtherDetails = this.invoice.invoiceOtherDetails.filter((_, _index) => _index !== index)
    },
    async submit () {
      this.invoice.invoiceDetails = [...this.invoice.invoiceDetailsDto]
      this.isSaving = true
      const result = await invoicesService.save(this.invoice)
      if (result) {
        toastService.show('Factura guardada')
        this.invoice = defaultInvoice()
      }
      this.isSaving = false
    }
  }
}
</script>

<style scoped>
.invoice-form {
  max-width: 500px;
  width: 100%;
}
</style>
