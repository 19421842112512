<template lang="pug">
table-picker(
  :data='devices'
  :id='id'
  :multiple='multiple'
  :onlyIds='onlyIds'
  :additional-columns='additionalColumns'
  :isLoading='isLoading'
  v-model='modelValue'
)
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TablePicker from '@/components/shared/TablePicker'

export default {
  components: { TablePicker },
  props: {
    id: { type: String },
    value: { type: [String, Array] },
    multiple: { type: Boolean },
    onlyIds: { type: Boolean }
  },
  data () {
    return {
      additionalColumns: [{ field: 'clientName', label: 'Cliente' }],
      modelValue: null
    }
  },
  async created () {
    await this.getDevices()
    this.modelValue = this.value
  },
  watch: {
    modelValue () {
      this.$emit('input', this.modelValue)
    },
    value () {
      this.modelValue = this.value
    }
  },
  computed: {
    ...mapState({
      devices: state => state.devices.devices,
      isLoading: state => state.devices.loading
    }),
    title () {
      return this.multiple ? 'Seleccione uno o varios equipos' : 'Seleccione un equipo'
    }
  },
  methods: {
    ...mapActions({
      getDevices: 'devices/getDevices'
    })
  }
}
</script>
