<template lang="pug">
span
  span.status-indicator(:class='`status-${invoice.status}`')
  span.ml-1(v-if='!onlyIcon') {{ statusDescription }}
</template>

<script>
import { invoiceStatusDescriptions } from '@/constants/enums'
export default {
  props: {
    invoice: { type: Object },
    onlyIcon: { type: Boolean }
  },
  computed: {
    statusDescription () {
      return invoiceStatusDescriptions[this.invoice.status]
    }
  }
}
</script>

<style lang="scss" scoped>
.status-indicator {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;

  // created
  &.status-1 {
    background: #e68b02;
  }

  // sended
  &.status-2 {
    background: #3452ea;
  }

  // Payed
  &.status-3 {
    background: #00a500;
  }

  // Cancelled
  &.status-4 {
    background: gray;
  }
}
</style>
