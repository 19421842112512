<template lang="pug">
div
  .picker-field
    a.picker-link(@click='openPicker')
      icon.icon(icon='window-maximize')
      span(v-if='deviceTextSelected') {{ deviceTextSelected }}
      i.empty-picker-link(v-else) ...
      input.dummy-input(:id='id' type='text' :required='required' :value='deviceTextSelected')
    router-link.external-link(:to='linkTo' v-if='linkTo && !hideLink')
        icon(icon='external-link-alt')
  b-modal(
    v-model='modalActive'
    has-modal-card
    trap-focus
    aria-role='dialog'
    aria-modal
    :can-cancel='["x"]'
  )
    modal-card(:title='title')
      b-table(:data='data' :loading='isLoading' narrowed hoverable mobile-cards striped checkable :checked-rows.sync='checked' default-sort-direction='asc' :default-sort='dataName')
        b-table-column(field='id' label='Identificador' sortable searchable v-slot='props') {{ props.row.id }}
        b-table-column(field='name' label='Nombre' sortable searchable v-slot='props') {{ props.row.name }}
        b-table-column(v-for='column in additionalColumns' :key='column.field' :field='column.field' :label='column.label' sortable searchable v-slot='props') {{ props.row[column.field] }}
        template(slot='empty')
          .no-data-found
</template>

<script>
export default {
  props: {
    id: { type: String },
    value: { type: [String, Array, Object, Number] },
    multiple: { type: Boolean },
    onlyIds: { type: Boolean },
    data: { type: Array },
    dataAsync: { type: Function },
    isLoading: { type: Boolean },
    required: { type: Boolean },
    dataId: { type: String, default: 'id' },
    dataName: { type: String, default: 'name' },
    additionalColumns: { type: Array, default: () => [] },
    name: { type: String },
    linkTo: { type: Object },
    hideLink: { type: Boolean }
  },
  data () {
    return {
      checked: [],
      modalActive: false
    }
  },
  async created () {
    this.assignValue()
  },
  watch: {
    checked () {
      if (this.multiple) {
        if (this.onlyIds) {
          this.$emit('input', this.checked.map(device => device.id))
        } else this.$emit('input', this.checked)
      } else if (this.checked.length === 1) {
        if (this.onlyIds) {
          this.$emit('input', this.checked[0].id)
        } else {
          this.$emit('input', this.checked[0])
        }
        this.modalActive = false
      } else if (this.checked.length > 1) {
        this.checked = [this.checked[this.checked.length - 1]]
      }
    },
    value () {
      this.assignValue()
    },
    data () {
      this.assignValue()
    }
  },
  computed: {
    deviceTextSelected () {
      return this.checked.map(device => device.name).join(', ') || this.name
    },
    title () {
      return this.multiple ? 'Seleccione uno o varios' : 'Seleccione uno'
    }
  },
  methods: {
    async openPicker () {
      if ((!this.data || !this.data.length) && this.dataAsync) {
        await this.dataAsync()
      }
      this.modalActive = true
    },
    assignValue () {
      if (this.multiple) {
        // this.data.filter(device => this.value && this.value.some(deviceValue => deviceValue.id))
        // TODO: Set multiple
        // console.warn('TODO: Set multiple')
      } else {
        const element = this.data.find(item => item[this.dataId] === this.value)
        if (element) {
          this.checked = [element]
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.picker-field {
  display: flex;
}

.picker-link {
  position: relative;
  display: flex;
}

.dummy-input {
  width: 100%;
  height: 0px;
  border: none;
  outline: none;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.empty-picker-link {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 5px;
  width: 12px;
}

.external-link {
  margin-left: 10px;
}
</style>
